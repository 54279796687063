/**
 * Lernwelt Backend API
 * Backend API for Lernwelt
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileShowDtoWithCaption } from './fileShowDtoWithCaption';


export interface ExtractedMedia { 
    iconName?: string;
    linkType?: ExtractedMedia.LinkTypeEnum;
    internalLinkTypeEnum?: ExtractedMedia.InternalLinkTypeEnumEnum;
    openInNewTab?: boolean;
    url?: string;
    text?: string;
    configurationAttributes?: { [key: string]: object; };
    files?: Array<FileShowDtoWithCaption>;
}
export namespace ExtractedMedia {
    export type LinkTypeEnum = 'external' | 'internal' | 'asset';
    export const LinkTypeEnum = {
        External: 'external' as LinkTypeEnum,
        Internal: 'internal' as LinkTypeEnum,
        Asset: 'asset' as LinkTypeEnum
    };
    export type InternalLinkTypeEnumEnum = 'MARKET_PLACE' | 'MEDIA_LIBRARY' | 'PAGE' | 'PROJECT' | 'LEARNING_CONTAINER' | 'PUBLIC_PAGE' | 'USER' | 'TASK_GROUP';
    export const InternalLinkTypeEnumEnum = {
        MarketPlace: 'MARKET_PLACE' as InternalLinkTypeEnumEnum,
        MediaLibrary: 'MEDIA_LIBRARY' as InternalLinkTypeEnumEnum,
        Page: 'PAGE' as InternalLinkTypeEnumEnum,
        Project: 'PROJECT' as InternalLinkTypeEnumEnum,
        LearningContainer: 'LEARNING_CONTAINER' as InternalLinkTypeEnumEnum,
        PublicPage: 'PUBLIC_PAGE' as InternalLinkTypeEnumEnum,
        User: 'USER' as InternalLinkTypeEnumEnum,
        TaskGroup: 'TASK_GROUP' as InternalLinkTypeEnumEnum
    };
}


