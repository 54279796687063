/**
 * Lernwelt Backend API
 * Backend API for Lernwelt
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ListItem { 
    htmlContent?: string;
    listItemType?: ListItem.ListItemTypeEnum;
    nodes?: Array<ListItem>;
}
export namespace ListItem {
    export type ListItemTypeEnum = 'Bullet' | 'Number' | 'Letter';
    export const ListItemTypeEnum = {
        Bullet: 'Bullet' as ListItemTypeEnum,
        Number: 'Number' as ListItemTypeEnum,
        Letter: 'Letter' as ListItemTypeEnum
    };
}


