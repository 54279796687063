/**
 * Lernwelt Backend API
 * Backend API for Lernwelt
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AbstractNodeChildrenInner } from './abstractNodeChildrenInner';


/**
 * Root of the editor tree.
 */
export interface EditorTreeRoot { 
    /**
     * List of child nodes in the editor tree.
     */
    children: Array<AbstractNodeChildrenInner>;
    /**
     * Contextual data associated with the editor tree. (e.g: content, settings, ...)
     */
    context: object;
    /**
     * Name of the chosen module.
     */
    moduleName: EditorTreeRoot.ModuleNameEnum;
    /**
     * Type of serializer used for saving the data.
     */
    serializerType?: string;
}
export namespace EditorTreeRoot {
    export type ModuleNameEnum = 'fr-task-group' | 'sus-title' | 'sus_box' | 'sus-question' | 'placeholder' | 'text' | 'mp_main_title' | 'mp_subtitle_one' | 'mp_subtitle_two' | 'mp_accordion' | 'mp_accordion_download' | 'mp_gallery' | 'mp-link' | 'mp_lead' | 'fr_lead' | 'iframe' | 'video' | 'two_column' | 'quotes' | 'image' | 'punctuation' | 'table' | 'expandable_module' | 'mp-expandable-module' | 'list_module' | 'root' | 'container' | 'fr-nav-tabs' | 'fr-accordion' | 'fr-textcard' | 'sus-media-box' | 'gg-content-group' | 'gg-if' | 'gg-text-input' | 'gg-number-input' | 'gg-text-select' | 'gg-button-select' | 'gg-comma-separated' | 'gg-single-choice' | 'gg-multiple-choice' | 'gg-switch' | 'gg-form-wrapper' | 'gg-form-control' | 'gg-visible-form-text' | 'gg-panel' | 'gg-system-context-text' | 'gg-list-system-context' | 'gg-list-user-text' | 'sv-complex-table' | 'horizontal-rule' | 'gg-output-type' | 'sv-block-link' | 'sus-three-cols-of-four' | 'sc-placeholder' | 'sc-text' | 'sc-image' | 'fr-textcard-page-link' | 'sus-video' | 'audio' | 'sus-taskgroup-gallery' | 'sus-learning-unit-gallery' | 'mp-literature-reference' | 'mp-article' | 'ri_media_slider' | 'public-page-heading-1' | 'public-page-heading-2' | 'public-page-heading-3' | 'mp-stripe-products' | 'mp-stripe-products-active';
    export const ModuleNameEnum = {
        FrTaskGroup: 'fr-task-group' as ModuleNameEnum,
        SusTitle: 'sus-title' as ModuleNameEnum,
        SusBox: 'sus_box' as ModuleNameEnum,
        SusQuestion: 'sus-question' as ModuleNameEnum,
        Placeholder: 'placeholder' as ModuleNameEnum,
        Text: 'text' as ModuleNameEnum,
        MpMainTitle: 'mp_main_title' as ModuleNameEnum,
        MpSubtitleOne: 'mp_subtitle_one' as ModuleNameEnum,
        MpSubtitleTwo: 'mp_subtitle_two' as ModuleNameEnum,
        MpAccordion: 'mp_accordion' as ModuleNameEnum,
        MpAccordionDownload: 'mp_accordion_download' as ModuleNameEnum,
        MpGallery: 'mp_gallery' as ModuleNameEnum,
        MpLink: 'mp-link' as ModuleNameEnum,
        MpLead: 'mp_lead' as ModuleNameEnum,
        FrLead: 'fr_lead' as ModuleNameEnum,
        Iframe: 'iframe' as ModuleNameEnum,
        Video: 'video' as ModuleNameEnum,
        TwoColumn: 'two_column' as ModuleNameEnum,
        Quotes: 'quotes' as ModuleNameEnum,
        Image: 'image' as ModuleNameEnum,
        Punctuation: 'punctuation' as ModuleNameEnum,
        Table: 'table' as ModuleNameEnum,
        ExpandableModule: 'expandable_module' as ModuleNameEnum,
        MpExpandableModule: 'mp-expandable-module' as ModuleNameEnum,
        ListModule: 'list_module' as ModuleNameEnum,
        Root: 'root' as ModuleNameEnum,
        Container: 'container' as ModuleNameEnum,
        FrNavTabs: 'fr-nav-tabs' as ModuleNameEnum,
        FrAccordion: 'fr-accordion' as ModuleNameEnum,
        FrTextcard: 'fr-textcard' as ModuleNameEnum,
        SusMediaBox: 'sus-media-box' as ModuleNameEnum,
        GgContentGroup: 'gg-content-group' as ModuleNameEnum,
        GgIf: 'gg-if' as ModuleNameEnum,
        GgTextInput: 'gg-text-input' as ModuleNameEnum,
        GgNumberInput: 'gg-number-input' as ModuleNameEnum,
        GgTextSelect: 'gg-text-select' as ModuleNameEnum,
        GgButtonSelect: 'gg-button-select' as ModuleNameEnum,
        GgCommaSeparated: 'gg-comma-separated' as ModuleNameEnum,
        GgSingleChoice: 'gg-single-choice' as ModuleNameEnum,
        GgMultipleChoice: 'gg-multiple-choice' as ModuleNameEnum,
        GgSwitch: 'gg-switch' as ModuleNameEnum,
        GgFormWrapper: 'gg-form-wrapper' as ModuleNameEnum,
        GgFormControl: 'gg-form-control' as ModuleNameEnum,
        GgVisibleFormText: 'gg-visible-form-text' as ModuleNameEnum,
        GgPanel: 'gg-panel' as ModuleNameEnum,
        GgSystemContextText: 'gg-system-context-text' as ModuleNameEnum,
        GgListSystemContext: 'gg-list-system-context' as ModuleNameEnum,
        GgListUserText: 'gg-list-user-text' as ModuleNameEnum,
        SvComplexTable: 'sv-complex-table' as ModuleNameEnum,
        HorizontalRule: 'horizontal-rule' as ModuleNameEnum,
        GgOutputType: 'gg-output-type' as ModuleNameEnum,
        SvBlockLink: 'sv-block-link' as ModuleNameEnum,
        SusThreeColsOfFour: 'sus-three-cols-of-four' as ModuleNameEnum,
        ScPlaceholder: 'sc-placeholder' as ModuleNameEnum,
        ScText: 'sc-text' as ModuleNameEnum,
        ScImage: 'sc-image' as ModuleNameEnum,
        FrTextcardPageLink: 'fr-textcard-page-link' as ModuleNameEnum,
        SusVideo: 'sus-video' as ModuleNameEnum,
        Audio: 'audio' as ModuleNameEnum,
        SusTaskgroupGallery: 'sus-taskgroup-gallery' as ModuleNameEnum,
        SusLearningUnitGallery: 'sus-learning-unit-gallery' as ModuleNameEnum,
        MpLiteratureReference: 'mp-literature-reference' as ModuleNameEnum,
        MpArticle: 'mp-article' as ModuleNameEnum,
        RiMediaSlider: 'ri_media_slider' as ModuleNameEnum,
        PublicPageHeading1: 'public-page-heading-1' as ModuleNameEnum,
        PublicPageHeading2: 'public-page-heading-2' as ModuleNameEnum,
        PublicPageHeading3: 'public-page-heading-3' as ModuleNameEnum,
        MpStripeProducts: 'mp-stripe-products' as ModuleNameEnum,
        MpStripeProductsActive: 'mp-stripe-products-active' as ModuleNameEnum
    };
}


